<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="w-90 center">
                <div class="flex justify-between items-center pv4-l pv3 mini-spacing">
                    <div>
                        <p class="pb2 f3 b">Paystack Integration</p>
                    </div>
                </div>
            </div>

            <main class="flex flex-wrap w-70 center mt3 adj-text">
                <div>
                    <header class="paystack-logo-header">
                        <img :src="require('@/assets/images/paystack-logo1.png')" alt="paystack-logo" width="300" />
                    </header>
                    <section class="paystack-section1 w-100">
                        <p class="paystack-section1-p">Add your <span>Paystack Live Secret Key</span></p>
                        <form @submit.prevent="completeIntegration">
                            <input
                                type="text"
                                v-model="state.paystackSecretKey"
                                class="pay-width paystack-section1-input"
                                required
                            />
                            <br />
                            <button type="submit" class="pay-width paystack-section1-button" :disabled="state.processing">
                                {{ state.processing ? 'Processing...' : 'Complete Setup' }}
                            </button>
                        </form>
                    </section>
                    <section class="paystack-section2">
                        <p class="paystack-section2-p">
                            To access your live secret key for Paystack, you need to follow these steps:
                            <ol>
                                <li>Log in to your <a href="https://dashboard.paystack.com/" target="_blank">Paystack account</a>.</li>
                                <li>Click on the "Settings" tab at the bottom left-hand corner of your dashboard.</li>
                                <li>Click on "API Keys & Webhooks" from the menu options.</li>
                                <li>Under the "API Configuration - Live Mode" section</li>
                                <li>You will see your live secret key. Copy the key</li>
                            </ol>
                            
                            Remember, the live secret key is confidential and should not be shared with anyone. It is used to authenticate and authorize requests made to the Paystack API on your behalf. <a href="https://simplebks.com/paystack.html">Learn more</a>.
                        </p>
                    </section>
                    <section class="paystack-section3">
                        <p class="paystack-back"><router-link :to="{ name: 'Integrations' }">&larr; Back</router-link></p>
                    </section>
                </div>
            </main>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { copyText } from 'vue3-clipboard'
import AppWrapper from '@/layout/AppWrapper'

export default {
    name: 'PaystackIntegration',
    components: { AppWrapper },

    setup() {
        const store = useStore()
        const router = useRouter()
        
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)

        const initialState = {
            processing: false,
            paystackSecretKey: '',
        }

        const state = reactive(initialState)

        const initPaystackSettings = () => {
            store.dispatch('Settings/getIntegrationSettings').then(response => {
                if (response?.status) {
                    state.paystackSecretKey = response?.data?.paystack?.secretKey || ''
                }
            })
        }

        const completeIntegration = () => {
            state.processing = true
            const payload = { paystackSecretKey: state.paystackSecretKey }
            store.dispatch('Settings/updatePaystackIntegration', payload).then(data => {
                state.processing = false
                if (data?.status) {
                    router.push({ name: 'PaystackWebhookDetails' })
                }
            })
        }

        onMounted(() => {
            initPaystackSettings()
        })

        return {
            state,
            completeIntegration,
            rolePermissions,
            role
        }
    }
}
</script>

<style>
.paystack-main {
    margin: 7% auto;
    width: 70%;
    box-sizing: border-box !important;
}
.paystack-logo-header {
    margin-bottom: 8%;
}
.paystack-section1-p {
    margin: 0;
}
.paystack-section1-input {
    margin: 8px 0;
}
.pay-width {
    width: 100%;
    box-sizing: border-box;
    height: 35px;
}
.paystack-section1-button {
    color: white;
    background-color: #132c8c;
    border: none;
    border-radius: 2px;
    margin-bottom: 2%;
}
.paystack-section2 {
    width: 80%;
}
.paystack-section2-p {
    line-height: 1.5em;
}
.paystack-section2-p a {
    text-decoration: none;
    color: #132c8c;
}
.paystack-section1-p span,
.paystack-section2-p span {
    background-color: #ffc107;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-weight: 700;
}
.paystack-section3 {
    width: 80%;
}
.paystack-back {
    margin-top: 50px;
    text-align: center;
}
.paystack-back a {
    text-decoration: none;
    color: #132c8c;
}
@media only screen and (max-width: 800px) {
    .paystack-logo-header {
        margin-bottom: 15%;
    }
}

.copy-text-wrapper {
    position: relative;
    padding: 5px;
    background: #fff;
    border: 1px solid #e3e8ee;
    border-radius: 4px;
    display: flex;
}
.copy-text-wrapper input.text {
    padding: 10px;
    font-size: 14px;
    color: #555;
    border: none !important;
    outline: none !important;
}
.copy-text-wrapper button {
    padding: 10px;
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
